import React from "react";
import { Nav } from "./features/navigation/Nav";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Nav />
      <article>
        <h1>Hallo Shippies</h1>
        <div>
          <p>hier ensteht die Webseite der Ship-Wreck-Lounge Comunity</p>
          <p>
            Langsam aber sicher wird die Seite mit Leben gefüllt werden. Einen
            öffentlichen und einen internen Raum wird es geben. Wir werden sehen
          </p>
          <p>Also noch ein wenig Geduld, dann wird es hier lebendiger.</p>
          <div
            className={["article-image-float-right", " image-smily-small"].join(
              " "
            )}
          ></div>
          <p>Wer Rechtschreibfehler findet darf sie gerne behalten.</p>
          <p>Viel Spass und bis demnächst!</p>
        </div>
      </article>
    </div>
  );
}

export default App;
