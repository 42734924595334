import React from "react";
import "../../index.css";

export function Nav() {
  return (
    <nav>
      <div className="logo"></div>
      <div className="mobileNav">Navigation</div>
    </nav>
  );
}
